<template>
  <div>
    <a
      class="btn btn-success btn-action mr-1"
      @click="bankStatementDetail"
      :disabled="dataDelete.length > 0"
      v-if="
        userp.role_id == '61829dd1e64b1b17688326df' ||
        userp.role_id == '61829dc7e64b1b17688326d5' ||
        userp.role_id == '6424071f50b77c6cf890ab0d' 
      "
    >
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <!-- <i class="fa fa-university"></i> -->
            <strong>Confirm</strong>
          </span>
      </template>
      <span>Confirm</span>
    </v-tooltip>
    </a>
    <!-- <v-icon color="orange"
            small
            class="mr-2"
            @click="updateUser"
            :disabled="dataDelete.length > 0"
            v-if="checkUserPermission('EDIT')"
    >mdi-pencil
    </v-icon>

    <v-icon color="red"
            small
            @click="deleteItem"
            
    > mdi-delete
    </v-icon> -->

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";


export default {
  props: ['darkmode', "item", "params", "dataDelete", "userPermission","userp"],
  data() {
    return {
      loading: false,
      dialogDelete: false,
    };
  },
  computed: {
    ...mapGetters("$_user", ["getCurrentUser"]),
  },
  methods: {
    
    async bankStatementDetail() {
      // console.log("this.item",this.item);
      await this.fetchCurrentBankStatement(this.item);
      this.fetchPopUp(true);
    },
    async updateUser() {
      await this.fetchCurrentBankStatement(this.item);
      this.fetchPopUp(true);
    },

    cancelDelete() {
      this.dialogDelete = false
    },

    async deleteItem() {
      await this.deleteData({
        vm: this,
        data: { _id: this.item._id}
      });
      await this.fetchListStatement(this.params)
      this.dialogDelete = false;
    },

    ...mapActions("$_role", ["fetchAllRolesDependOnUser"]),
    ...mapActions("$_withdrawPending", ["fetchPopUp", "fetchCurrentBankStatement", "deleteData", "fetchListStatement"]),
  },
};
</script>
